<template>

  <!-- Router View -->
  <router-view />

</template>

<script>

export default { name: 'Generic' }

</script>
